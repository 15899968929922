<template>
  <div class="center">
    <transition 
      appear 
      appear-active-class="fade-enter-active"
    >
      <img alt="logo" src="./assets/logo.png">
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body {
  font-family: 'Roboto Condensed', sans-serif;
  background: black;
  height: 100vh;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  height: 200px;
  margin: 20px;
}

.fade-enter-active {
  animation: go 15s;
}

@keyframes go {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
